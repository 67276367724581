var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "two-thirds" } },
            [
              _c("gov-heading", { attrs: { size: "l" } }, [
                _vm._v("Taxonomy: Categories")
              ]),
              _c("gov-body", [
                _vm._v(
                  "\n        Taxonomies are the 'tags' that we assign to services, in order for\n        them to appear within search results and categories.\n      "
                )
              ]),
              _c("gov-body", [
                _vm._v(
                  "\n        From this page, you can edit the taxonomies available to be applied to\n        a service, as well as add new ones.\n      "
                )
              ])
            ],
            1
          ),
          _vm.auth.isSuperAdmin
            ? _c(
                "gov-grid-column",
                { attrs: { width: "one-third" } },
                [
                  _c(
                    "gov-button",
                    {
                      attrs: {
                        to: { name: "taxonomies-categories-create" },
                        success: "",
                        expand: ""
                      }
                    },
                    [_vm._v("Add a new category")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("gov-section-break", { attrs: { size: "l" } }),
      _vm.loading
        ? _c("ck-loader")
        : _vm._l(_vm.taxonomies, function(taxonomy, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "gov-grid-row",
                  [
                    _c(
                      "gov-grid-column",
                      { attrs: { width: "two-thirds" } },
                      [
                        _c(
                          "gov-heading",
                          { attrs: { size: "m" } },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(taxonomy.name) +
                                " \n          "
                            ),
                            _vm.auth.isGlobalAdmin
                              ? _c(
                                  "gov-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "taxonomies-categories-edit",
                                        params: { taxonomy: taxonomy.id }
                                      }
                                    }
                                  },
                                  [_vm._v("\n            Edit\n          ")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        taxonomy.children.length > 0
                          ? _c(
                              "gov-list",
                              { attrs: { bullet: "" } },
                              _vm._l(taxonomy.children, function(taxonomy) {
                                return _c(
                                  "li",
                                  { key: taxonomy.id },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(taxonomy.name) +
                                        " \n            "
                                    ),
                                    _vm.auth.isGlobalAdmin
                                      ? _c(
                                          "gov-link",
                                          {
                                            attrs: {
                                              to: {
                                                name:
                                                  "taxonomies-categories-edit",
                                                params: {
                                                  taxonomy: taxonomy.id
                                                }
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              Edit\n            "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    taxonomy.children.length > 0
                                      ? _c(
                                          "gov-list",
                                          { attrs: { bullet: "" } },
                                          _vm._l(taxonomy.children, function(
                                            taxonomy
                                          ) {
                                            return _c(
                                              "li",
                                              { key: taxonomy.id },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(taxonomy.name) +
                                                    " \n                "
                                                ),
                                                _vm.auth.isGlobalAdmin
                                                  ? _c(
                                                      "gov-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name:
                                                              "taxonomies-categories-edit",
                                                            params: {
                                                              taxonomy:
                                                                taxonomy.id
                                                            }
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  Edit\n                "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                taxonomy.children.length > 0
                                                  ? _c(
                                                      "gov-list",
                                                      { attrs: { bullet: "" } },
                                                      _vm._l(
                                                        taxonomy.children,
                                                        function(taxonomy) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key: taxonomy.id
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    " +
                                                                  _vm._s(
                                                                    taxonomy.name
                                                                  ) +
                                                                  " \n                    "
                                                              ),
                                                              _vm.auth
                                                                .isGlobalAdmin
                                                                ? _c(
                                                                    "gov-link",
                                                                    {
                                                                      attrs: {
                                                                        to: {
                                                                          name:
                                                                            "taxonomies-categories-edit",
                                                                          params: {
                                                                            taxonomy:
                                                                              taxonomy.id
                                                                          }
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                      Edit\n                    "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              taxonomy.children
                                                                .length > 0
                                                                ? _c(
                                                                    "gov-list",
                                                                    {
                                                                      attrs: {
                                                                        bullet:
                                                                          ""
                                                                      }
                                                                    },
                                                                    _vm._l(
                                                                      taxonomy.children,
                                                                      function(
                                                                        taxonomy
                                                                      ) {
                                                                        return _c(
                                                                          "li",
                                                                          {
                                                                            key:
                                                                              taxonomy.id
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  taxonomy.name
                                                                                ) +
                                                                                " \n                        "
                                                                            ),
                                                                            _vm
                                                                              .auth
                                                                              .isGlobalAdmin
                                                                              ? _c(
                                                                                  "gov-link",
                                                                                  {
                                                                                    attrs: {
                                                                                      to: {
                                                                                        name:
                                                                                          "taxonomies-categories-edit",
                                                                                        params: {
                                                                                          taxonomy:
                                                                                            taxonomy.id
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                          Edit\n                        "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            taxonomy
                                                                              .children
                                                                              .length >
                                                                            0
                                                                              ? _c(
                                                                                  "gov-list",
                                                                                  {
                                                                                    attrs: {
                                                                                      bullet:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  _vm._l(
                                                                                    taxonomy.children,
                                                                                    function(
                                                                                      taxonomy
                                                                                    ) {
                                                                                      return _c(
                                                                                        "li",
                                                                                        {
                                                                                          key:
                                                                                            taxonomy.id
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                            " +
                                                                                              _vm._s(
                                                                                                taxonomy.name
                                                                                              ) +
                                                                                              " \n                            "
                                                                                          ),
                                                                                          _vm
                                                                                            .auth
                                                                                            .isGlobalAdmin
                                                                                            ? _c(
                                                                                                "gov-link",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    to: {
                                                                                                      name:
                                                                                                        "taxonomies-categories-edit",
                                                                                                      params: {
                                                                                                        taxonomy:
                                                                                                          taxonomy.id
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                              Edit\n                            "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          taxonomy
                                                                                            .children
                                                                                            .length >
                                                                                          0
                                                                                            ? _c(
                                                                                                "gov-list",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    bullet:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                _vm._l(
                                                                                                  taxonomy.children,
                                                                                                  function(
                                                                                                    taxonomy
                                                                                                  ) {
                                                                                                    return _c(
                                                                                                      "li",
                                                                                                      {
                                                                                                        key:
                                                                                                          taxonomy.id
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n                                " +
                                                                                                            _vm._s(
                                                                                                              taxonomy.name
                                                                                                            ) +
                                                                                                            " \n                                "
                                                                                                        ),
                                                                                                        _vm
                                                                                                          .auth
                                                                                                          .isGlobalAdmin
                                                                                                          ? _c(
                                                                                                              "gov-link",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  to: {
                                                                                                                    name:
                                                                                                                      "taxonomies-categories-edit",
                                                                                                                    params: {
                                                                                                                      taxonomy:
                                                                                                                        taxonomy.id
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "\n                                  Edit\n                                "
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          : _vm._e()
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  }
                                                                                                ),
                                                                                                0
                                                                                              )
                                                                                            : _vm._e()
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  0
                                                                                )
                                                                              : _vm._e()
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          }),
                                          0
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _c("gov-body", [_vm._v("No taxonomies.")])
                      ],
                      1
                    )
                  ],
                  1
                ),
                index < _vm.taxonomies.length - 1
                  ? _c("gov-section-break", { attrs: { size: "l" } })
                  : _vm._e()
              ],
              1
            )
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }